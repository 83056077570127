<template>
  <vx-card  style="padding-left: 10px">

    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="open_add_level_popup()"
          icon-pack="feather"
          icon="icon-edit"
        >Add Level</vs-button>
      </div>
    </div>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      :sst="true"
      :total="totalDocs"
      :data="users"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="dataTableParams.limit"
      :noDataText="noDataText"
      search
    >
      <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between" >
        <div style='float: left'>
          <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit" class="mr-6">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in limitOptions" :clearable="false"/>
          </vs-select>
        </div>
      </div>

      <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
           <vs-th sort-key="action">Action</vs-th>
      </template>

      <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">
                    {{ data[indextr].name}}
                </vs-td>
              <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>

              <vs-td :data="data[indextr]._id">
                  <p>
                    <vx-tooltip text="Edit Level"  position="left" >
                    <vs-icon class="mr-2" icon-pack="feather" icon="icon-edit" size="small" @click="open_update_level_popup(data[indextr])"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Delete Level"  position="left" >
                    <vs-icon class="mr-2" icon-pack="feather" icon="icon-trash" size="small" @click="openDeleteConfirm(data[indextr]._id)"></vs-icon>
                    </vx-tooltip>
                  </p>
                  </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </div>

 <vs-popup classContent="popup-example"  title="Add Level" :active.sync="popupActivo2">
    <vs-input class="w-full mt-4" label="Level name" v-model="newLevel.name" v-validate="'required'" data-vv-as="level" data-vv-validate-on="blur" name="level" />
          <span class="text-danger text-sm"  v-show="errors.has('level')"><p>{{ errors.first('level') }}</p></span>
          <vs-button color="success" class="ml-auto mt-4" @click="add_level"> Add Level</vs-button>
  </vs-popup>

 <vs-popup classContent="popup-example"  title="Edit Level" :active.sync="popupActivo3">
    <vs-input class="w-full mt-4" label="Level name" v-model="newLevel.name" v-validate="'required'" data-vv-as="level" data-vv-validate-on="blur" name="levelEdit" />
          <span class="text-danger text-sm"  v-show="errors.has('levelEdit')"><p>{{ errors.first('levelEdit') }}</p></span>
          <vs-button color="success" class="ml-auto mt-4" @click="update_level"> Update Level</vs-button>
  </vs-popup>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions } from 'vuex';
import moment from "moment";

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      noDataText:'No levels available..',
      currentPage: 1,
      dataTableParams: {
        searchKey: "",
        sort: "name",
        dir: "asc",
        page: 1,
        limit: 25
      },
      newLevel: {
        _id: '',
        name: ''
      },
      popupActivo2: false,
      popupActivo3: false,
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };
  },
  methods: {
    ...mapActions('general', ['fetchLevels','addLevel','removeLevel','updateLevel']),
    moment(date){
      return moment(date)
    },
    getLevelList() {
      let self = this;
      this.fetchLevels(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
      })

    },
    add_level(){
      const levelName = this.newLevel.name
      this.addLevel({levelName}).then(res=> {
        this.$vs.notify({
          title: 'Add',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.popupActivo2 = false
      this.getLevelList();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      this.popupActivo2 = false
      })
    },
    update_level(){
      const levelId = this.newLevel._id
      const name = this.newLevel.name
      this.updateLevel({levelId, name}).then(res=> {
        this.$vs.notify({
          title: 'Update',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.popupActivo3 = false
      this.getLevelList();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      this.popupActivo3 = false
      })
    },
    open_add_level_popup(){
      this.popupActivo2 = true
      this.newLevel = { _id: '', name: ''}
    },
    open_update_level_popup(rowData){
      this.popupActivo3 = true
      this.newLevel._id = rowData._id
      this.newLevel.name = rowData.name
    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.searchKey = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLevelList();
    },
     handleChangePage(page) {
      this.dataTableParams.page = page;
       this.getLevelList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.currentPage = 1;
      this.$refs.table.currentx = 1;
      this.getLevelList();
    },
    createTutorHandler(){
      this.$router.push({ name: 'AddTutor'})
    },
    viewDetailHandler(id) {
      this.$router.push({ name: 'TutorDetails', params: { tutorId: id } })
    },
    editDetailHandler(id) {
      this.$router.push({ name: 'EditTutorDetails', params: { tutorId: id } })
    },
    openDeleteConfirm(levelId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete this level.`,
        accept: this.deleteDetailHandler,
        acceptText: "Delete",
        parameters: levelId
      })
    },
    deleteDetailHandler(levelId) {
      this.removeLevel({levelId}).then(res => {
        this.$vs.notify({
          title: 'Delete',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.getLevelList();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  watch: {
  	'dataTableParams.page': function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.dataTableParams.page = newVal;
        this.getLevelList();
      }
    },
    'dataTableParams.limit': function(newlimit, oldLimit) {
      if(newlimit !== oldLimit){
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getLevelList()
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getLevelList();
  }
};
</script>
<style scoped>
.actionButtons{
  display: inline-block;
}
.actionButtons .actionButton{
  float: left;
}
</style>
